import { router } from "@inertiajs/vue3";
import DOMPurify from "dompurify";
import { marked } from "marked";

export function openUrl(url, { target, queryParams } = {}) {
    const finalUrl = formatUrl(url, { queryParams });

    if (target === "_blank") {
        window.open(finalUrl, target);
        return;
    }
    if (isInIFrame()) {
        if (target) {
            window.open(finalUrl, target);
        } else {
            window.location.href = finalUrl;
        }
    } else {
        router.visit(finalUrl);
    }
}

/**
 * checks if a current page is loaded in an iframe
 * used as a quick way to check if we are in a microsoft teams instance
 * if we ever load our app into an iframe for another reason this needs to be reworked
 *
 * @returns {boolean} true if the current page is loaded in an iframe
 */
export const isInIFrame = () => {
    return window.self !== window.top;
};

export function toMarkdown(content) {
    const html = marked(content);
    return DOMPurify.sanitize(html);
}

/**
 * Creates a chat URL with the appropriate chat source parameter
 * @param {string} baseUrl - The base URL for the chat
 * @param {object} queryParams - The query parameters to add to the URL
 * @returns {string} The complete URL with query parameters attached
 */
export function formatUrl(baseUrl, { queryParams }) {
    let formattedUrl = baseUrl;
    try {
        formattedUrl = new URL(baseUrl);
    } catch (error) {
        formattedUrl = new URL(baseUrl, window.location.origin);
    }

    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            formattedUrl.searchParams.set(key, value);
        });
    }

    return formattedUrl.toString();
}
